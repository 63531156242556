import React from "react";
import { Link,graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../stylesheets/life.scss";
import Article from "../components/dataschema/article";
import Breadcrumb from "../components/dataschema/breadcrumb";





const BlogPost = ({ data }) => {


  const { title, description, body, heroImage, tags, createdAt, updatedAt } = data.contentfulLife;
  const categoryTypes = data.allContentfulBlogpostCategory.edges;
  const recentPosts = data.allContentfulLife.edges;
  return (

    <Layout>
      <SEO title={title} keywords={[`gatsby`, `application`, `react`]} />

      <div className="container blogpost-hero">
        <div className="row">
          <div className="col-9">
            <img src={heroImage.fixed.src} alt={heroImage.title} className="img-fluid heroImage"/>
          </div>
        </div>
      </div>
      <div className="container blogpost">
        <div className="row">
          <div className="col-12 col-md-9">
            <div className="row">
              <div className="col-10">
              <h1>{title}</h1>
              </div>
              <div className="col col-md-8">
              <nav aria-label="breadcrumb" className="custom-breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item">About</li>
                <li className="breadcrumb-item"><a href={typeof window !== 'undefined' ? window.location.origin + '/about/life' : ''}>life</a></li>
                <li className="breadcrumb-item active" aria-current="page">{title}</li>
              </ol>
            </nav>
              </div>
              <div className="col col-md-4">
              <p className="date text-right">{createdAt}</p>
              </div>
            </div>


            <div className="body-text"
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html,
              }}
            ></div>
          </div>
          <div className="col-12 col-md-3">
          <h3>Recent Posts</h3>
          <div className="recentPosts">
              {recentPosts.map(post => (
                <p key={post}>
                  <Link to={typeof window !== 'undefined' ? window.location.origin +"/about/life/"+ post.node.slug : ''}>
{post.node.title}</Link>
                </p>
              ))}
            </div>
            <h3>Categories</h3>
            <div className="categoryTypes">
            {categoryTypes.map(catTypes => (
                 <p key={catTypes}>{catTypes.node.blogpostCategoryName} ({catTypes.node.life.length !== null ? catTypes.node.life.length : '0'})</p>
              ))}
              </div>
            <h3>Tags</h3>
            <div className="tags">
              {tags.map(tag => (
                <p key={tag}>
                  {tag}
                </p>
              ))}
            </div>
          </div>

        </div>
      </div>

      <Article title={title} createdAt={createdAt} updatedAt={updatedAt} image={heroImage.file.url} />
      
      <Breadcrumb lvl1_name="home" lvl1_url={typeof window !== 'undefined' ? window.location.origin : ''} lvl2_url={typeof window !== 'undefined' ? window.location.origin + '/about/life' : ''} lvl2_name="Life" lvl3_name={title} lvl3_url={typeof window !== 'undefined' ? window.location.href : ''}/>


    </Layout>


  );
};
export default BlogPost;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulLife(slug: { eq: $slug }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      createdAtFormat : createdAt
      createdAt(formatString: "MMMM DD, YYYY")
      updatedAt
      description {
        content {
          content {
            value
          }
        }
      }
      heroImage {
        fluid(maxHeight: 500, maxWidth: 1000) {
          src
        }
        fixed(height: 400, width: 1200,cropFocus: CENTER, quality:100) {
          src
        }
        file {
          url
        }
        title
      }
      tags
    }
    category : contentfulBlogpostCategory {
      blogpostCategoryName
    }
    allContentfulLife(limit: 4, sort: {order: DESC, fields: createdAt}) {
      edges {
        node {
          title
          slug
        }
      }
    }
    allContentfulBlogpostCategory {
      edges {
        node {
          blogpostCategoryName
          life {
            title
          }
        }
      }
    }
  }
  
`;
