//import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import LogoJPG from "../../images/logo.jpg"
import StructuredData from 'react-google-structured-data';

function Article(seoInfo) {

  const title = seoInfo.title;
  const createdAt = new Date(seoInfo.createdAt);
  const updatedAt = seoInfo.updatedAt;
  const image = seoInfo.image;


    return (
        <StructuredData
        type='Organization'
        data={{
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://google.com/article"
            },
            "headline": title,
            "image": [image],
            "datePublished": createdAt,
            "dateModified": updatedAt,
            "author": {
              "@type": "Person",
              "name": "Justin Abercrombia"
            },
            "publisher": {
              "@type": "Organization",
              "name": "jabercrombia",
              "logo": {
                "@type": "ImageObject",
                "url": LogoJPG
              }
            }
        }}
    />
      )
  }

  Article.propTypes = {
    updatedAt: PropTypes.string,
    title: PropTypes.string,
  }
  
  Article.defaultProps = {
    updatedAt: ``,
    title:'',
  }

export default Article;
  